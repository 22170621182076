import _baseGetTag from "./_baseGetTag";
import _isArray from "./isArray";
import _isObjectLike from "./isObjectLike";
var exports = {};
var baseGetTag = _baseGetTag,
  isArray = _isArray,
  isObjectLike = _isObjectLike;

/** `Object#toString` result references. */
var stringTag = "[object String]";

/**
 * Checks if `value` is classified as a `String` primitive or object.
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a string, else `false`.
 * @example
 *
 * _.isString('abc');
 * // => true
 *
 * _.isString(1);
 * // => false
 */
function isString(value) {
  return typeof value == "string" || !isArray(value) && isObjectLike(value) && baseGetTag(value) == stringTag;
}
exports = isString;
export default exports;